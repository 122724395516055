/* Styles */
import "@/scss/main.scss";

import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import Axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";
//import JsonExcel from "vue-json-excel";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import * as VueGoogleMaps from "gmap-vue";
import CountryFlag from "vue-country-flag";
import VerticalTimeline from "vue-vertical-timeline";
import LightTimeline from "vue-light-timeline";

Vue.use(LightTimeline);
Vue.use(VerticalTimeline);
Vue.component("country-flag", CountryFlag);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBt7HOfCWpD8pUxoMVfRCbvDae_iaqiqjY",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    region: "LA",
    language: "lo",
  },

  installComponents: true,
});

Vue.use(Buefy);
Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.mixin(mixin);
//Vue.component("downloadExcel", JsonExcel);

//Axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "https://kkl-m5iaz.ondigitalocean.app" : "http://localhost:3900";
Axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "" : "http://localhost:3900";
Axios.interceptors.request.use(
  (config) => {
    let token = store.get("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    if (response.status) {
      if (response.data.token) {
        store.set("token", response.data.token);
      }
      return Promise.resolve(response.data);
    } else Promise.reject(response);
  },
  (error) => {
    if (error && error.response) {
      const message = process.env.NODE_ENV == "production" ? "" : JSON.stringify(error.response.data.message);
      switch (error.response.status) {
        case 401:
          Swal.fire(error.response.statusText, message, "warning");
          router.replace("/login");
          return Promise.reject(error.response.statusText);
        default:
          return Promise.reject(error.response.data ? error.response.data : error.response);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
//("https://www.google.com/maps/rpc/shorturl?authuser=0&hl=en&gl=la&pb=!1s");
Object.defineProperty(Vue.prototype, "_", {
  value: _,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
