import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/Public.vue"),
    children: [
      {
        path: "",
        component: () => import("@/views/Index.vue"),
      },
      {
        path: "/index",
        component: () => import("@/views/Index.vue"),
      },
      {
        path: "/login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/branch",
        component: () => import("@/views/Branch.vue"),
      },
      {
        path: "/policy",
        component: () => import("@/views/Policy.vue"),
      },
      {
        path: "/price",
        component: () => import("@/views/Price.vue"),
      },
      {
        path: "/track/:code",
        component: () => import("@/views/Track.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/Print.vue"),
    children: [
      {
        meta: {
          title: "ການຂົນສົ່ງ",
        },
        path: "/shipmentprint/:code",
        name: "shipmentprint",
        props: true,
        component: () => import("@/views/ShipmentPrint.vue"),
      },
      {
        meta: {
          title: "ShipmentScriptPrint",
        },
        path: "/ShipmentScriptPrint/:code",
        name: "ShipmentScriptPrint",
        component: () => import("@/views/ShipmentScriptPrint.vue"),
      },
      {
        meta: {
          title: "PrintBatch",
        },
        path: "/printbatch/:batchno",
        name: "PrintBatch",
        component: () => import("@/views/PrintBatch.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        meta: {
          title: "Dashboard",
        },
        path: "/home",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        meta: {
          title: "Profile",
        },
        path: "/profile",
        name: "profile",
        component: () => import("@/views/Profile.vue"),
      },
      {
        meta: {
          title: "ChangePassword",
        },
        path: "/changepassword",
        name: "changepassword",
        component: () => import("@/views/ChangePassword.vue"),
      },
      {
        meta: {
          title: "ເພີ່ມການຂົນສົ່ງ",
        },
        path: "/addshipment",
        name: "addshipmentform",
        component: () => import("@/views/ShipmentForm.vue"),
      },
      {
        meta: {
          title: "ແກ້ໄຂການຂົນສົ່ງ",
        },
        path: "/updateshipment/:code",
        name: "editshipmentform",
        component: () => import("@/views/ShipmentForm.vue"),
      },
      {
        meta: {
          title: "ເຄື່ອງຖືກຍົກເລີກການສົ່ງ",
        },
        path: "/canceledshipment",
        name: "CanceledShipment",
        component: () => import("@/views/CanceledShipment.vue"),
      },
      {
        meta: {
          title: "ປ່ຽນປາຍທາງ",
        },
        path: "/rejectshipment",
        name: "RejectShipment",
        component: () => import("@/views/RejectShipment.vue"),
      },
      {
        meta: {
          title: "ປ່ຽນປາຍທາງ",
        },
        path: "/shipmentrejecttosbranch",
        name: "ReportShipmentReject",
        component: () => import("@/views/ReportShipmentReject.vue"),
      },//shipmentrejecttosbranch
       
      {
        meta: {
          title: "ເຄື່ອງຖືກອາຍັດ",
        },
        path: "/holdingshipment",
        name: "HoldingShipment",
        component: () => import("@/views/HoldingShipment.vue"),
      },
      {
        meta: {
          title: "ເບິ່ງການຂົນສົ່ງ",
        },
        path: "/shipment/:code",
        name: "viewshipmentform",
        props: true,
        component: () => import("@/views/ShipmentView.vue"),
      },
      {
        meta: {
          title: "ລູກຄ້າ",
        },
        path: "/customers",
        name: "customers",
        component: () => import("@/views/Customers.vue"),
      },
      /*{
        meta: {
          title: "ເຄື່ອງຕີກັບ",
        },
        path: "/rejects",
        name: "Rejects",
        component: () => import("@/views/Rejects.vue"),
      },*/
      {
        meta: {
          title: "ສາຂາ",
        },
        path: "/branches",
        name: "branches",
        component: () => import("@/views/Branches.vue"),
      },
      {
        meta: {
          title: "ແຂວງ",
        },
        path: "/provinces",
        name: "provinces",
        component: () => import("@/views/Provinces.vue"),
      },
      {
        meta: {
          title: "ພະນັກງານ",
        },
        path: "/users",
        name: "users",
        component: () => import("@/views/Users.vue"),
      },
      {
        meta: {
          title: "ເມືອງ",
        },
        path: "/districts",
        name: "districts",
        component: () => import("@/views/Districts.vue"),
      },
      {
        meta: {
          title: "ພັດສະດຸໃນສາຂາ",
        },
        path: "/shipments",
        name: "shipments",
        component: () => import("@/views/Shipments.vue"),
      },
      {
        meta: {
          title: "COD",
        },
        path: "/cod",
        name: "cod",
        component: () => import("@/views/Cod.vue"),
      },
      {
        meta: {
          title: "ToDeliverParcels",
        },
        path: "/ToDeliverParcels",
        name: "ToDeliverParcels",
        component: () => import("@/views/ToDeliverParcels.vue"),
      },
      {
        meta: {
          title: "LoadParcelsToBus",
        },
        path: "/loadParcelsToBus",
        name: "LoadParcelsToBus",
        component: () => import("@/views/LoadParcelsToBus.vue"),
      },
      {
        meta: {
          title: "LoadParcelsFromBus",
        },
        path: "/LoadParcelsFromBus",
        name: "LoadParcelsFromBus",
        component: () => import("@/views/LoadParcelsFromBus.vue"),
      },
      {
        meta: {
          title: "Vehicles",
        },
        path: "/vehicles",
        name: "Vehicles",
        component: () => import("@/views/Vehicles.vue"),
      },
      {
        meta: {
          title: "Tracking",
        },
        path: "/tracking/:code",
        name: "Tracking",
        component: () => import("@/views/Tracking.vue"),
      },
      {
        meta: {
          title: "Prices",
        },
        path: "/prices",
        name: "Prices",
        component: () => import("@/views/Prices.vue"),
      },
      {
        meta: {
          title: "ParcelTypes",
        },
        path: "/ParcelTypes",
        name: "ParcelTypes",
        component: () => import("@/views/ParcelTypes.vue"),
      },
      {
        meta: {
          title: "ShipmentScript",
        },
        path: "/ShipmentScript",
        name: "ShipmentScript",
        component: () => import("@/views/ShipmentScript.vue"),
      },
      {
        meta: {
          title: "ShipmentScriptView",
        },
        path: "/ShipmentScriptView/:code",
        name: "ShipmentScriptView",
        component: () => import("@/views/ShipmentScriptView.vue"),
      },
      {
        meta: {
          title: "Banks",
        },
        path: "/banks",
        name: "Banks",
        component: () => import("@/views/Banks.vue"),
      },
      {
        meta: {
          title: "Exchange Rates",
        },
        path: "/exchangerates",
        name: "ExchangeRates",
        component: () => import("@/views/ExchangeRates.vue"),
      },
      {
        meta: {
          title: "Report Shipments",
        },
        path: "/report-shipments",
        name: "ReportShipments",
        component: () => import("@/views/ReportShipments.vue"),
      },
      {
        meta: {
          title: "Report Shipments Status",
        },
        path: "/report-shipmentsstatus",
        name: "ReportShipmentsStatus",
        component: () => import("@/views/ReportShipmentsByStatus.vue"),
      },
      {
        meta: {
          title: "Report Shipments Status",
        },
        path: "/shipmentfrombranch",
        name: "ShipmentFromBranch",
        component: () => import("@/views/ReportShipmentFromBranch.vue"),
      },
      {
        meta: {
          title: "Branch settlement",
        },
        path: "/summary-settle-of-branch",
        name: "BranchSettlementSummary",
        component: () => import("@/views/BranchSettlementSummary.vue"),
      },
      {
        meta: {
          title: "Report Shipments By Branch",
        },
        path: "/shipmenttosbranch",
        name: "ReportShipmentToBranch",
        component: () => import("@/views/ReportShipmentToBranch.vue"),
      },
      {
        meta: {
          title: "Report COD Shipments",
        },
        path: "/report-codshipments",
        name: "ReportCODShipments",
        component: () => import("@/views/ReportCODShipments.vue"),
      },
      {
        meta: {
          title: "Report COD On Destination branch",
        },
        path: "/codondesbranch",
        name: "ReportCODOnDesBranch",
        component: () => import("@/views/ReportCODOnDesBranch.vue"),
      },
      {
        meta: {
          title: "Report Shipment Receiver pay On Destination branch",
        },
        path: "/receiverpayondesbranch",
        name: "ReportRecievrPayOnDesBranch",
        component: () => import("@/views/ReportReceiverPayOnDesBranch.vue"),
      },
      {
        meta: {
          title: "Report Shipments Fee",
        },
        path: "/report-sendparcelsfee",
        name: "ReportSendParcelsFee",
        component: () => import("@/views/ReportSendParcelsOnBranchFee.vue"),
      },
      {
        meta: {
          title: "Report Shipments Fee",
        },
        path: "/report-receiveparcelsfee",
        name: "ReportReceiveParcelsFee",
        component: () => import("@/views/ReportReceiveParcelsOnBranchFee.vue"),
      },
      {
        meta: {
          title: "Package waiting for pickup",
        },
        path: "/pickup-request-list",
        name: "PickupRequestList",
        component: () => import("@/views/PickupRequestList.vue"),
      },
      {
        meta: {
          title: "Package to pickup",
        },
        path: "/pickup-list",
        name: "PickupList",
        component: () => import("@/views/PickupList.vue"),
      },
      {
        meta: {
          title: "Batch Shipment Form",
        },
        path: "/batchshipmentform/:sendertel",
        name: "BatchShipmentForm",
        component: () => import("@/views/BatchShipmentForm.vue"),
      },
      {
        meta: {
          title: "To Deliver Parcel",
        },
        path: "/todeliverparcel/:code",
        name: "ToDeliverParcel",
        component: () => import("@/views/ToDeliverParcel.vue"),
      },
      {
        meta: {
          title: "Customer information",
        },
        path: "/customer-info/:id",
        name: "CustomersInformation",
        component: () => import("@/views/CustomerInfo"),
      },
      {
        meta: {
          title: "Customer Form",
        },
        path: "/customerForm/:id",
        name: "customerForm",
        component: () => import("@/views/CustomerForm"),
      },
      {
        meta: {
          title: "Batch Shipment View",
        },
        path: "/batchshipment/:batchno",
        name: "BatchShipmentView",
        component: () => import("@/views/BatchShipmentView"),
      },
      {
        meta: {
          title: "Ledgers",
        },
        path: "/ledgers",
        name: "Ledgers",
        component: () => import("@/views/Ledgers"),
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (
    to.path !== "/" &&
    !to.path.startsWith("/track/") &&
    to.path !== "/login" &&
    to.path !== "/index" &&
    to.path !== "/price" &&
    to.path !== "/policy" &&
    to.path !== "/branch"
  ) {
    if (!store.get("user") || !store.get("token")) {
      next("login");
      return;
    }
  }
  next();
});

export default router;
