import Vue from "vue";
import vuex from "vuex";
import pathify from "./pathify";
import { make } from "vuex-pathify";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);
const state = {
  username: null,
  user: null,
  role: null,
  token: null,
  provinces: null,
  districts: null,
  branches: null,
  banks: null,
  currencies: null,
  paymentmethods: null,
  exchangerate: null,
  parceltypes: null,
  shipmentStatuses: null,
  datelocal: "fr-FR",

  /* NavBar */
  isNavBarVisible: true,

  /* FooterBar */
  isFooterBarVisible: true,

  /* Aside */
  isAsideVisible: true,
  isAsideMobileExpanded: true,
  notification: {
    class: "is-info",
    message: "OK",
  },
  asideMobileStateToggle(state, payload = null) {
    const htmlClassName = "has-aside-mobile-expanded";

    let isShow;

    if (payload !== null) {
      isShow = payload;
    } else {
      isShow = !state.isAsideMobileExpanded;
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }

    state.isAsideMobileExpanded = isShow;
  },
};
var mutations = make.mutations(state);
mutations.asideMobileStateToggle = (state, payload = null) => {
  const htmlClassName = "has-aside-mobile-expanded";

  let isShow;

  if (payload !== null) {
    isShow = payload;
  } else {
    isShow = !state.isAsideMobileExpanded;
  }

  if (isShow) {
    document.documentElement.classList.add(htmlClassName);
  } else {
    document.documentElement.classList.remove(htmlClassName);
  }

  state.isAsideMobileExpanded = isShow;
};
var store = new vuex.Store({
  state,
  mutations,
  getters: {
    getBranch: (state) => {
      return state.branches;
    },
    getUserBranchId: (state) => {
      return state.user.branch_id;
    },
    getUserIdBranchInfo: (state) => {
      return state.user.branch.code + "_" + state.user.id;
    },
    getLocale: (state) => {
      return state.datelocal;
    },
    getShipmentStatus: (state)=>(status) =>{
      return state.shipmentStatuses[status].label;
    },
  },
  plugins: [
    pathify.plugin,
    createPersistedState({
      key: "KKL",
      storage: window.localStorage,
    }),
  ],
});

global.store = store;
export default store;
