import moment from "moment";

var CryptoJS = require("crypto-js");

export default {
  data: function() {
    return {};
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    datetime(x) {
      if (x) {
        return moment(x).format("DD-MM-YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return moment(x).format("DD-MM-YYYY");
      }
      return x;
    },
  },
  methods: {
    sha512(s) {
      return CryptoJS.SHA512(s).toString();
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    datetime(x) {
      if (x) {
        return moment(x).format("YYYY-MM-DD HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return moment(x).format("YYYY-MM-DD");
      }
      return x;
    },
  },
  computed: {
    isAndroid() {
      try {
        return window.AndroidApp !== undefined;
      } catch (e) {
        return false;
      }
    },
    getDateLocale() {
      return "en-GB";
    },
  },
};
