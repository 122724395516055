<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.$store.set("notification", {
      class: "is-info",
      message: "OK",
    });
  },
};
</script>

<style lang="scss">
* {
  font-family: "NotosansLao" !important;
}
input::-moz-placeholder {
  font-family: "NotosansLao" !important;
}
</style>
